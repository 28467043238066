import { useEffect, useState } from "react";

import { BaseModal, InputField, SmallButtonYellow } from 'component-library'

import { EmailCheck } from '@regex/EmailCheck'
import { requestPasswordReset } from "@api/API";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useNotification } from "@context/Notification/NotificationContext";

type ResetPassModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
  type?: string | null;
  initialEmail?: string | null;
}

export const ResetPassModalConstructor = ({ 
  openModal, 
  toggleModal, 
  showCloseButton = false, 
  darkMode = true,
  type,
  initialEmail 
}: ResetPassModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const customNavigate = useCustomNavigate();
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    email: initialEmail || '',
    password: ''
  })
  const [newPassRequested, setNewPassRequested] = useState(false)

  useEffect(() => {
    // Only validate email if it's not a partner activation
    if (type !== 'partner_activation') {
      if (loginDetails.email.length == 0 || EmailCheck(loginDetails.email)) {
        setIsError(false)
      } else {
        setIsError(true)
      }
    }
  }, [loginDetails.email, type])

  const handleResetPass = async() => {
    setIsLoading(true)
    const response = await requestPasswordReset(loginDetails.email)
    if(response.status === 200){
      setIsCompleted(true)
      setIsLoading(false)
      setNewPassRequested(true)
    } else {
      console.log('error', response)
      notify(t('requestPasswordError'), "error")
      setIsLoading(false)
      setNewPassRequested(true)
    }
  }

  const getModalTitle = () => {
    if (newPassRequested) return 'Wachtwoord aangevraagd';
    if (type === 'partner_activation') return 'Je aanmelding';
    return 'Wachtwoord resetten';
  }

  const getModalSubtitle = () => {
    if (newPassRequested) {
      return `Er is een e-mail verzonden naar ${loginDetails.email} met instructies om je wachtwoord opnieuw in te stellen.`;
    }
    if (type === 'partner_activation') {
      return `Klik op onderstaande knop en ontvang een e-mail op ${loginDetails.email} met instructies om je wachtwoord in te stellen.`;
    }
    return t('resetPasswordSubtitle').replace('{{email}}', loginDetails.email);
  }

  const renderContent = () => {
    if (!newPassRequested) {
      return (
        <div className="flex-1 flex flex-col">
          {type === 'partner_activation' ? (
            <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
              <div className="mb-2 font-bold">
                {loginDetails.email}
              </div>
            </div>
          ) : (
            <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
              <InputField
                placeholder={t('email')}
                value={loginDetails.email}
                setState={() => {}}
                type="text"
                error={isError}
                handleUpdate={(value: string) => setLoginDetails(prev => ({ ...prev, email: value }))}
                itemKey={'email'}
                darkMode={darkMode}
                errorMsg={t('emailError')}
              />
            </div>
          )}
          <div className='w-full px-8 mt-8'>
            <SmallButtonYellow
              text="Nieuw wachtwoord aanvragen"
              onClick={() => handleResetPass()}
              disabled={loginDetails.email.length === 0 || isError || isCompleted}
              darkMode={darkMode}
              loading={isLoading}
            />
          </div> 
          <div className='w-full pt-4 px-8'>
            <div 
              className={`${darkMode ? "text-white" : "text-black"} text-center cursor-pointer underline`} 
              onClick={()=>customNavigate('/inloggen')}
            >
              Terug
            </div>
          </div>
        </div> 
      );
    }

    return (
      <div className="flex flex-col flex-1">
        <div className="flex-1">
        </div>
        <div className='w-full pt-4 px-8'>
          <div 
            className={`${darkMode ? "text-white" : "text-black"} text-center cursor-pointer underline`} 
            onClick={()=>customNavigate('/inloggen')}
          >
            Terug naar inloggen
          </div>
        </div>
      </div>
    );
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={getModalTitle()}
      modalSubtitle={getModalSubtitle()}
    >
      {renderContent()}
    </BaseModal>
  )
}