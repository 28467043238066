import { useEffect, useState } from "react";
import { BaseModal, InputField, SmallButtonYellow } from 'component-library'

import { useTranslation } from "react-i18next";
import { PasswordCheck } from "@regex/PasswordCheck";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useSearchParams } from "react-router-dom";
import { checkCompromisedPassword, login, setNewPassword } from "@api/API";
import { useNotification } from "@context/Notification/NotificationContext";
import { CheckIcon, XMarkIcon } from "@heroicons/react/16/solid";

type NewPassModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const NewPassModalConstructor = ({ openModal, toggleModal, darkMode = true }: NewPassModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const customNavigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('activation_code') || ""
  const email = searchParams.get('email') || ""
  const [loginDetails, setLoginDetails] = useState({
    repeatPassword: '',
    password: ''
  })

  const [validations, setValidations] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    isLongEnough: false,
    isNotComporimised: false
  });

  const validatePassword = async(password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8;
    const isNotComporimised = await checkPassword()

    setValidations({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isLongEnough,
      isNotComporimised
    });
  };
  
  const updateLoginDetails = (value: string, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      validatePassword(loginDetails.password);
    }, 250);
  
    return () => clearTimeout(timeoutId);
  }, [loginDetails.password]);


  const checkPassword = async() => {
    if(loginDetails.password.length < 7) return false
    let res = await checkCompromisedPassword(loginDetails.password)
    if(res.status === 200) return true
    else return false
    // return false
  }

  const handleNewPass = async() => {
    let response = await setNewPassword(decodeURIComponent(email), code, loginDetails.password)
    if(response.status === 200){
      notify(t('newPasswordSuccess'), "success")
      customNavigate('/inloggen')
    }
    else {
      notify(t('newPasswordError'), "error")
      console.log('error', response)
    }
  }

  const checkDisabled = () => {
    if(validations.isNotComporimised) return false
    if(loginDetails.password.length > 0 && loginDetails.repeatPassword.length > 0 && loginDetails.password === loginDetails.repeatPassword){
      return false
    }
    return true
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('setPassword')}
      modalSubtitle={t('setPasswordSubtitle')}
    >
      <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
        <InputField
          placeholder={t('password')}
          value={loginDetails.password}
          setState={() => {}}
          type="password"
          error={false}
          handleUpdate={updateLoginDetails}
          itemKey={'password'}
          darkMode={darkMode}
        />
        {(loginDetails.password.length > 0 && (!PasswordCheck(loginDetails.password) || !validations.isNotComporimised)) &&
          <div className="text-sm">
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasUpperCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordUpperCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasLowerCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordLowerCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasNumber ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordNumber")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.isLongEnough ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordIsLongEnough")}
              </div>
            </div>
            {loginDetails.password.length > 7 &&
              <div className="flex flex-row gap-x-2">
                <div>
                {validations.isNotComporimised ?
                  <CheckIcon className={`w-5 h-5 text-green-500`} />
                  :
                  <XMarkIcon className={`w-5 h-5 text-red-500`} />
                }
                </div>
                <div>
                  {"Wachtwoord is gevonden in database met gelekte wachtwoorden."}
                </div>
              </div>
            }
          </div>
        }
        <InputField
          placeholder={t('repeatPassword')}
          value={loginDetails.repeatPassword}
          setState={() => {}}
          type="password"
          error={loginDetails.repeatPassword.length > 0 && loginDetails.repeatPassword !== loginDetails.password}
          handleUpdate={updateLoginDetails}
          itemKey={'repeatPassword'}
          darkMode={darkMode}
          errorMsg={t('repeatPasswordError')}
        />
      </div>
      <div className='w-full px-8 mt-8 '>
        <SmallButtonYellow
          text={"Instellen"}
          onClick={() => handleNewPass()}
          disabled={checkDisabled()}
          darkMode={darkMode}
        />
      </div>
      <div className='w-full pt-4 px-8'>
        <div className={`${darkMode ? "text-white" : "text-black"} text-center cursor-pointer underline`} onClick={()=>customNavigate('/inloggen')}>
          {t('back')}
        </div>
      </div>
    </BaseModal>
  )
}