import { useEffect, useState } from "react";
import { BaseModal } from 'component-library'
// import TwoFactorAuthInputs from "../2fa/TwoFactor";
// import { login, twoFactorCode } from "../api/API";

import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { useUser } from "@context/UserContext";


type SelectOriginModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  origin?: string;
  darkMode?: boolean;
}

export const SelectOriginModalConstructor = ({ openModal, toggleModal, showCloseButton = false, origin = '', darkMode = true }: SelectOriginModalProps) => {
  const { t } = useTranslation()
  const BASE_URL = import.meta.env.VITE_BASE_URL as string
  const customNavigate = useCustomNavigate();
  const { isAuth, user, handleLogout } = useUser() as any;
  const [options, setOptions] = useState([
    {label: 'cjp', value: 'cjp'}
  ]) 

  
  useEffect(() =>{
    if(user){
      checkUser()
    }
  }, [user, isAuth])

  const checkUser = () => {
    if(isAuth){
      setRedirectOptions()
    } else {
      customNavigate('/inloggen')
    }     
  }

  const setRedirectOptions = () => {
    if((user?.teacherPlus === true || user?.role === 'school_for_budgetholder')){
      // console.log('user.role', user.role)
      let arr = [
        // {label: 'educatie', value: 'educatie'},
        {label: 'individual', value: 'individual'},
        {label: user.role, value: user.role}
      ]
      setOptions([...arr])
    }
    else if(platformRoles.scholen.includes(user.role)){
      let arr = [
        // {label: 'educatie', value: 'educatie'},
        {label: 'individual', value: 'individual'},
        {label: user.role, value: user.role}
      ]
      setOptions([...arr])
    }
    else if(platformRoles.partners.includes(user.role)){
      let arr = [
        {label: 'cjp', value: 'cjp'},
        {label: user.role, value: user.role}
      ]
      setOptions([...arr])
    }
    else if(platformRoles.mijn.includes(user.role)){
      let arr = [
        {label: 'cjp', value: 'cjp'},
        {label: user.role, value: user.role}
      ]
      setOptions([...arr])
    }
  }

  // const addUserTypeToOptions = () => {
  //   if(user && user.role){ 
  //     const newUserType = {label: user.role, value: user.role}
  //     setOptions([...options, newUserType])
  //   } 
  // }

  const platformRoles = {
    admin: ['admin', 'cjp_admin', 'cjp_director', 'cjp_members_head', 'cjp_finance_head', 'cjp_marketing_head', 'cjp_user', 'cjp_viewer' ],
    scholen: ['school_for_budgetholder', 'school_for_teacher', 'school_for_student'],
    mijn: ['individual', 'school_for_student', 'parent'],
    partners: ['part_comp_for_key_person']
  } as any; 

  const findPlatformForKey = (selectedRole: string) => {
    for (const [platform, roles] of Object.entries(platformRoles)) {
      if ((roles as string[]).includes(selectedRole)) {
        return platform;
      }
    }
    return null; // Return null if no platform matches the selected role
  };

  const userTypeRedirect = async (selectedRole: string) => {
    if(findPlatformForKey(selectedRole) === 'mijn'){
      customNavigate('/account')
    } else {
      if (selectedRole === 'cjp') {
        window.location.href = 'https://www.cjp.nl';
        return;
      } 
      if (selectedRole === 'educatie') {
        window.location.href = getOrigin('educatie');
        return;
      }
      const platformKey = findPlatformForKey(selectedRole);
      if (platformKey) {
        window.location.href = await getOrigin(platformKey);
      } else {
        // Default redirection if no platform matches
        window.location.href = 'https://www.cjp.nl';
      }
    }
  };

  const getLabel = (key: string) => {
    switch(key){
      case 'educatie':
        return 'Educatie Portaal'
      case 'school_for_budgetholder':
      case 'school_for_teacher':
        return 'Scholen Portaal'
      case 'cjp_director':
      case 'cjp_admin':
      case 'cjp_members_head':
        return 'Admin'
      case 'individual':
      case 'school_for_student':
        return 'Mijn CJP Pas'
      case 'cjp':
        return 'CJP.nl'
      case 'part_comp_for_key_person':
        return 'Partners'
      default:
        return ''
    }
  }

  const getOrigin = (selected: string) => {
    const url = `http://${selected}.${BASE_URL}`
    return url
  }

  const handleClickedLogOut = async () => {
    if(handleLogout)
      handleLogout()
  }


  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('selectOrigin')}
    > 
      <div className={`${darkMode ? "text-white" : "text-black"}`}>
        <div>
          <div className='font-bold'>{t("selectOriginMessage")}</div> {t("selectOriginSubtitle")}
        </div> 
      </div>
      <div className="flex flex-1 flex-col gap-y-4 w-full px-4">
        {options.map((item, index) => (
          <div key={index} onClick={()=>userTypeRedirect(item.value)} className="cursor-pointer w-full h-fit border-2 rounded-lg p-4 text-lg font-bold flex items-center hover:border-main-color hover:shadow-lg animate">
            {getLabel(item.label)}
          </div> 
        ))}
      </div>
      <div className="text-center hover:underline cursor-pointer" onClick={()=>handleClickedLogOut()}>
        Uitloggen
      </div>
    </BaseModal>
  )
}